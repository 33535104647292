<script setup lang="ts">
import type { IconName } from '~/components/ui/Icon.vue';
import type { MinimalArea } from '../Index.vue';

const props = defineProps<{
  area: MinimalArea;
  showAddButton?: boolean;
}>();

defineEmits<{
  (e: 'addChild' | 'clickOnArea'): void;
}>();

// Computed
const getIconForArea = computed((): IconName => {
  switch (props.area.area_type) {
    case 'factory':
      return 'Factory';
    case 'utility':
      return 'Plug';
    case 'workshop':
      return 'Warehouse';
    case 'machine':
      return 'Cog';
    case 'peripheral':
      return 'ToyBrick';
    case 'office':
      return 'MonitorSmartphone';
    default:
      return 'Factory';
  }
});

const getEnergyType = computed(() => {
  const energyTypes: { icon: IconName; color: string; backgroundColor: string }[] = [];
  props.area.meters.forEach((meter) => {
    if (meter.meter_type.energy_type === 'Electricity') {
      energyTypes.push({
        icon: 'Zap',
        color: 'text-yellow-500',
        backgroundColor: 'bg-yellow-100',
      });
    } else if (meter.meter_type.energy_type === 'Gas') {
      energyTypes.push({
        icon: 'Flame',
        color: 'text-red-500',
        backgroundColor: 'bg-orange-100',
      });
    } else if (meter.meter_type.energy_type === 'Water') {
      energyTypes.push({
        icon: 'Droplets',
        color: 'text-blue-500',
        backgroundColor: 'bg-blue-100',
      });
    }
  });
  return energyTypes;
});
</script>
<template>
  <div class="relative">
    <div
      class="rounded-lg shadow-sm cursor-pointer border bg-white border-gray-200 px-4 py-3 hover:ring-2 hover:border-primary-500 hover:ring-primary-500 !ring-opacity-15 transition-all duration-100"
      @click="$emit('clickOnArea')"
    >
      <div class="flex items-center gap-2 mb-2">
        <div class="w-6 h-6 bg-green-100 rounded-md flex items-center justify-center">
          <ui-icon :name="getIconForArea" class="w-4 h-4 text-primary" />
        </div>
        <span class="font-medium text-gray-900">{{ area.name }}</span>
      </div>
      <div class="flex items-center gap-1.5">
        <div class="flex items-center gap-1.5">
          <div
            v-for="energy in getEnergyType"
            :key="energy.icon"
            class="w-6 h-6 rounded-md flex items-center justify-center"
            :class="energy.backgroundColor"
          >
            <ui-icon :name="energy.icon" class="w-4 h-4" :class="energy.color" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="showAddButton" class="absolute left-1/2 -translate-x-1/2 -bottom-6">
      <ui-button variant="primary" size="xs" class="!rounded-full !w-8 !h-8 !p-0 shadow-xl" left-icon="Plus" @click="$emit('addChild')" />
    </div>
  </div>
</template>
