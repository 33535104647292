<script setup lang="ts">
import { VueFlow, useVueFlow } from '@vue-flow/core';
import { Background } from '@vue-flow/background';
import { ref, nextTick } from 'vue';
import '@vue-flow/core/dist/style.css';
import type { Edge, Node } from '@vue-flow/core';
import BaseNode from './nodes/BaseNode.vue';
import dagre from 'dagre';

export interface MinimalArea {
  id: number;
  parent_id?: number;
  name: string;
  [key: string]: any; // Permet d'avoir des propriétés supplémentaires
}

defineEmits<{
  (e: 'addChild' | 'clickOnArea', areaId: number): void;
}>();

const props = defineProps<{
  areas: MinimalArea[];
  showAddNodeButton?: boolean;
}>();

const nodes = ref<Node[]>([]);
const edges = ref<Edge[]>([]);
const { fitView } = useVueFlow();

const getNodeDimensions = async () => {
  await nextTick();
  const nodeElements = document.querySelectorAll('.vue-flow__node');
  const nodeDimensions = new Map();

  nodeElements.forEach((element) => {
    const nodeId = element.getAttribute('data-id');
    if (nodeId) {
      const rect = element.getBoundingClientRect();
      nodeDimensions.set(nodeId, {
        width: rect.width,
        height: rect.height,
      });
    }
  });

  return nodeDimensions;
};

const transformAreasToFlow = async (areas: MinimalArea[]) => {
  // Créer d'abord tous les nœuds
  const newNodes: Node[] = areas.map((area) => ({
    id: area.id.toString(),
    type: 'base',
    position: { x: 0, y: 0 },
    draggable: false,
    data: {
      area,
    },
    class: 'rounded-lg',
  }));

  // Créer ensuite toutes les arêtes
  const newEdges: Edge[] = areas
    .filter((area) => area.parent_id)
    .map((area) => ({
      id: `e${area.parent_id}-${area.id}`,
      source: area.parent_id.toString(),
      target: area.id.toString(),
      type: 'smoothstep',
      animated: false,
      label: '',
      style: { stroke: '#10b981', strokeWidth: 2 },
    }));

  // Mettre à jour les refs
  nodes.value = [];
  edges.value = [];
  await nextTick();
  nodes.value = newNodes;
  edges.value = newEdges;

  // Attendre le rendu initial
  await nextTick();

  // Obtenir les dimensions réelles
  const nodeDimensions = await getNodeDimensions();

  // Configurer le graphe dagre
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({
    rankdir: 'TB',
    nodesep: 80,
    ranksep: 100,
    edgesep: 50,
  });

  // Ajouter les nœuds avec leurs dimensions réelles
  newNodes.forEach((node) => {
    const dimensions = nodeDimensions.get(node.id) || { width: 200, height: 80 };
    dagreGraph.setNode(node.id, dimensions);
  });

  // Ajouter les arêtes
  newEdges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  // Calculer le layout
  dagre.layout(dagreGraph);

  // Mettre à jour les positions des nœuds
  const updatedNodes = newNodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    const dimensions = nodeDimensions.get(node.id) || { width: 200, height: 80 };
    return {
      ...node,
      position: {
        x: nodeWithPosition.x - dimensions.width / 2,
        y: nodeWithPosition.y - dimensions.height / 2,
      },
    };
  });

  // Mettre à jour les refs une dernière fois
  nodes.value = [];
  edges.value = [];
  await nextTick();
  nodes.value = updatedNodes;
  edges.value = newEdges;
};

watch(
  () => props.areas,
  async (newAreas) => {
    if (newAreas) {
      await transformAreasToFlow(newAreas);
      await nextTick();
      setTimeout(() => {
        fitView();
      }, 200);
    }
  },
  { immediate: true },
);
</script>

<template>
  <div class="h-[70vh] w-full">
    <VueFlow
      v-model="nodes"
      :edges="edges"
      :default-viewport="{ x: 0, y: 0, zoom: 1 }"
      :default-edge-options="{
        type: 'smoothstep',
        animated: true,
        style: { stroke: '#10b981', strokeWidth: 2 },
      }"
      :fit-view-on-init="true"
      :auto-connect="false"
      :elevate-edges-on-select="true"
    >
      <Background pattern-color="#BDBDBD" :gap="20" :size="2" />
      <template #node-base="nodeProps">
        <BaseNode
          :area="nodeProps.data.area"
          :show-add-button="showAddNodeButton"
          @add-child="$emit('addChild', nodeProps.data.area.id)"
          @click-on-area="$emit('clickOnArea', nodeProps.data.area.id)"
        />
      </template>
    </VueFlow>
  </div>
</template>

<style>
.vue-flow {
  @apply h-full w-full;
}

.vue-flow__node {
  @apply cursor-default;
}

.vue-flow__edge {
  pointer-events: all;
}

.vue-flow__edge path {
  stroke: #10b981;
  stroke-width: 2;
}

.vue-flow__edge.animated path {
  stroke-dasharray: 5;
  animation: dashdraw 0.5s linear infinite;
}

@keyframes dashdraw {
  from {
    stroke-dashoffset: 10;
  }
}
</style>
